export const lowerCharacters = "abcdefghijklmnopqrstuvwxyz";

export function randomCharacter(): string {
  const index = Math.floor(Math.random() * lowerCharacters.length);

  return lowerCharacters[index];
}

export function isLetter(key: string): boolean {
  return key.length == 1 ? key >= "A" && key <= "z" : false;
}

export function isNumber(key: string): boolean {
  return key.length == 1 ? key >= "0" && key <= "9" : false;
}

export function isSpace(key: string): boolean {
  return key == ' ';
}