
































/* eslint-disable */
import { Component, Vue } from 'vue-property-decorator';
import { bus } from '../main';
import { randomCharacter, isLetter, isNumber, isSpace } from '../services/util';

@Component({
  components: {
  },
})
export default class Home extends Vue {
  private gameEngine: GameEngine = new GameEngine(bus);

  public created(): void {
    window.addEventListener('keydown', this.gameEngine.keyDown.bind(this.gameEngine))
    window.addEventListener('keyup', this.gameEngine.keyUp.bind(this.gameEngine))
  }
}

class GameEngine {
  public level: Level;
  public levelNumber: number;
  public timer: number;
  public interval: any;
  public attempts: number;
  public pauses: number;
  public solutions: KeyboardEvent[][] = [];
  public state: string = 'running';
  public reason: string = '';
  public attempted: boolean = false;
  public completed: boolean = false;

  constructor(private bus: Vue) {
    this.levelNumber = 1;
    this.level = new Intro(this.solutions);
    this.timer = this.level.time();
    this.attempts = 0;
    this.pauses = 0;

    this.interval = setInterval(() => {
      this.timer--;
      if (this.timer == 0) {
        bus.$emit('timer:zero');
      }
    }, 1000);

    bus.$on('level:complete', (solution: KeyboardEvent[]) => {
      this.solutions.push(solution);
      this.completed = true;
      window.setTimeout(() => this.completed = false, 100)
      this.levelNumber++;
      const nextLevel = this.generateLevel();

      if (nextLevel) {
        this.level = new nextLevel(this.solutions);
        this.timer = this.level.time();
      } else {
        bus.$emit('game:complete');
      }
    })

    bus.$on('level:attempt', (event: KeyboardEvent) => {
      this.attempts++;
      this.attempted = true;
      window.setTimeout(() => this.attempted = false, 100)
      if (this.attempts >= this.level.attempts()) {
        bus.$emit('game:failed', 'too many attempts');
      }
    })

    bus.$on('timer:zero', () => {
      if (!this.level.complete()) {
        bus.$emit('game:failed', 'ran out of time');
      } else {
        bus.$emit('level:complete');
      }
    })

    bus.$on('game:complete', () => {
      bus.$emit('game:failed', 'ran out of levels');
    })

    bus.$on('game:failed', (reason: string) => {
      this.state = 'failed';
      this.reason = reason;
      clearInterval(this.interval);
    })
  }

  public keyDown(event: KeyboardEvent) {
    if (this.state == 'running' && !event.repeat && (isLetter(event.key) || isNumber(event.key) || isSpace(event.key))) {
      this.level.keyDown(event);
    }
  }

  public keyUp(event: KeyboardEvent) {
    if (this.state == 'running' && !event.repeat && (isLetter(event.key) || isNumber(event.key) || isSpace(event.key))) {
      this.level.keyUp(event);
    }
  }

  private generateLevel(): new (solutions: KeyboardEvent[][]) => Level {
      if (this.level.next) {
        return this.level.next();
      }

      if (this.levelNumber % 10 == 0) {
        this.pauses++;
        return PAUSES[Math.floor(Math.random() * Math.min(this.pauses, PAUSES.length))];
      }
      return RANDOMS[Math.floor(Math.random() * Math.min(this.levelNumber, RANDOMS.length))];
  }
}

abstract class Level {
  constructor(protected solutions: KeyboardEvent[][]) { }

  abstract message(): string;
  next: (() => new (solutions: KeyboardEvent[][]) => Level) | null = null;
  complete: (event?: KeyboardEvent) => boolean = () => false;
  letters: () => string  = () => '';
  time: () => number  = () => 10;
  attempts: () => number = () => 3;
  modifier: () => string | null = () => null;
  keyDown(event: KeyboardEvent): void {
    if (this.complete(event)) {
      bus.$emit('level:complete', [event]);
    } else {
      bus.$emit('level:attempt', [event]);
    }
    event.preventDefault();
  }
  keyUp(event: KeyboardEvent): void {
    event.preventDefault();
  }
}

abstract class SingleCharacterPressLevel extends Level {
  protected letter = randomCharacter();
  letters = () => this.letter;
  complete = (event?: KeyboardEvent) => !!event && ((event!.key.toLowerCase() === this.letter) && (this.modifier() == null || (event![this.modifier() as keyof KeyboardEvent] == true)));
}

abstract class EncodedSinglePress extends SingleCharacterPressLevel {
  message = () => this.encoder('press this key');
  time = () => 30;
  letters = () => String.fromCharCode(this.letter.charCodeAt(0) + 1);
  abstract encoder(str: string): string;
}

abstract class MultiCharacterPressLevel extends Level {
  private _letters: string[] = [];
  private index: number = 0;
  private solution: KeyboardEvent[] = [];
  private buffer: string = '';
  constructor(solutions: KeyboardEvent[][]) {
    super(solutions);
    for (let i = 0; i < this.size(); i++) {
      this._letters.push(randomCharacter());
    }
  }
  abstract size(): number;
  letters = () => this._letters.join('');
  keyDown = (event: KeyboardEvent) => {
    if (event.key.toLowerCase() == this._letters[this.index]) {
      this.index++;
      this.buffer += event.key.toLowerCase();
      this.solution.push(event);
      if (this.buffer == this.letters()) {
        bus.$emit('level:complete', this.solution);
      }
    } else {
      bus.$emit('level:attempt', event);
    }
  }
}

abstract class HoldLevel extends Level {
  private _letters: string[] = [];
  private holding: string[] = [];
  constructor(solutions: KeyboardEvent[][]) {
    super(solutions);
    while (this._letters.length < this.size()) {
      const ch = randomCharacter();
      if (!this._letters.includes(ch)) {
        this._letters.push(ch);
      }
    }
  }
  abstract size(): number;
  letters = () => this._letters.join('');
  keyDown = (event: KeyboardEvent) => {
    if (this._letters.includes(event.key)) {
      if (!this.holding.includes(event.key)) {
        this.holding.push(event.key);
        console.log('Holding ' + this.holding.join(''));
      }
    } else {
      bus.$emit('level:attempt', event);
    }
  }

  keyUp = (event: KeyboardEvent) => {
    if (this.holding.includes(event.key)) {
      this.holding = this.holding.filter(i => i !== event.key);
    }
    console.log('Holding ' + this.holding.join(''));
  }

  complete = (event?: KeyboardEvent) => this.holding.length == this._letters.length;
}

abstract class PauseLevel extends Level {
  complete = (event?: KeyboardEvent) => true;
}

abstract class DontPressLevel extends Level {
  protected letter = randomCharacter();
  letters = () => this.letter;
  keyDown = (event: KeyboardEvent) => {
    if (event.key.toLowerCase() == this.letter) {
      bus.$emit('level:attempt');
    }
  }
  complete = (event?: KeyboardEvent) => true;
}

class Intro extends Level {
  message = () => 'press any key'
  complete = (event?: KeyboardEvent) => true;
  next = () => SingleKey;
  time = () => 10;
}

class SingleKey extends SingleCharacterPressLevel {
  message = () => 'press this key'
  time = () => 10;
}

class SingleHold extends HoldLevel {
  message = () => 'hold this key';
  time = () => 3;
  size() { return 1; };
}

class Memory extends Level {
  message = () => 'press the key you pressed in level 001';
  letters = () => '';
  time = () => 10;
  complete = (event?: KeyboardEvent) => !!event && (event!.key == this.solutions[0][0].key)
}

class QuickSingleKey extends SingleCharacterPressLevel {
  message = () => 'press this key quickly!';
  time = () => 2;
}

class ShiftKey extends SingleCharacterPressLevel {
  message = () => 'shift this key';
  time = () => 10;
  modifier = () => 'shiftKey';
}

class ControlKey extends SingleCharacterPressLevel {
  message = () => 'control this key';
  time = () => 10;
  modifier = () => 'ctrlKey';
}

class MultiType extends MultiCharacterPressLevel {
  message = () => 'type these keys';
  time = () => 10;
  size() { return 3; };
}

class MultiHold extends HoldLevel {
  message = () => 'hold these keys';
  time = () => 5;
  size() { return 3; };
}

class FutilityPause extends PauseLevel {
  private letter = randomCharacter();
  message = () => 'ponder the futility of pressing this key';
  letters = () => this.letter;
  keyDown = (event: KeyboardEvent) => {
    if (event.key !== this.letter) {
      bus.$emit('level:attempt');
    }
  }
}

class DontPress extends DontPressLevel {
  message = () => `Don't press this key`
  time = () => 3;
}

class Rot1Press extends EncodedSinglePress {
  encoder(str: string) {
    return str.split('').map(c => c == ' ' ? ' ' : String.fromCharCode(c.charCodeAt(0) + 1)).join('');
  }
}

const RANDOMS = [SingleKey, Rot1Press, Memory, SingleHold, QuickSingleKey, SingleHold, QuickSingleKey, ShiftKey, ControlKey, MultiType, MultiHold, DontPress];
const PAUSES = [FutilityPause]
